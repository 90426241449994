.svg-container {
    $origin: 461.9px 176.3px;

    width: 100%;
    margin: 0 auto;
    padding: 0 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 1440px;
    svg {
        width: 100%;
        height: 100%;

        max-width: 1080px;
        min-height: 383px;
    }
    .groups-wheel {
        cursor: pointer;
        &:hover {
            .groupe {
                opacity: 1;
            }
            .hover-seg {
                opacity: 1;

                fill: $blue;
            }
            .line {
                circle {
                    fill: $blue;
                }
                path {
                    stroke: $blue;
                }
            }
        }
    }
    .groupe {
        opacity: 0;

        transition: $transition;
    }
    .hover-seg {
        opacity: 0;

        fill: $blue;
    }
    .line {
        circle, path {
            transition: $transition;
        }
    }
    .ronds {
        transform-origin: $origin;

        transition: transform 0.3s;
    }

    #governance:hover ~ #rond-1 {
        transform: rotate(-90deg);
    }
    #governance:hover ~ #rond-2 {
        transform: rotate(90deg);
    }
    #governance:hover ~ #rond-3 {
        transform: rotate(-90deg);
    }

    #security:hover ~ #rond-1 {
        transform: rotate(90deg);
    }
    #security:hover ~ #rond-2 {
        transform: rotate(-90deg);
    }
    #security:hover ~ #rond-3 {
        transform: rotate(90deg);
    }

    #itsec:hover ~ #rond-1 {
        transform: rotate(-90deg);
    }
    #itsec:hover ~ #rond-2 {
        transform: rotate(-180deg);
    }
    #itsec:hover ~ #rond-3 {
        transform: rotate(-90deg);
    }

    #threat:hover ~ #rond-1 {
        transform: rotate(90deg);
    }
    #threat:hover ~ #rond-2 {
        transform: rotate(-90deg);
    }
    #threat:hover ~ #rond-3 {
        transform: rotate(90deg);
    }
}

.is-touchscreen {
    .groupe {
        opacity: 1;
        &.hover-seg {
            opacity: 0;
        }
    }
}

//Sizes
$padding: 100px;
//Colors
$black: #151515;
$grey: #9f9f9f;
$light-grey: #f7f7f7;
$mid-grey: #f4f4f4;
$white: #ffffff;
$blue: #1c88a0;
//Social colors
$facebook: #3b5999;
$twitter: #55acee;
$instagram: #e4405f;
$linkedin: #0077b5;
$youtube: #cd201f;
// Column & gutter
$col: 6.07%;
$gut: 2.46%;
//Media queries
$tablet: 1024px;
$mobile: 700px;
$ie: "all and (-ms-high-contrast: none), (-ms-high-contrast: active)";
// Fonts
$font-family: "sofia-pro",sans-serif;
$font-light: 400;
$font-regular: 600;
$font-semibold: 700;
$font-bold: 900;
//Easings
$transition: all 0.3s ease-in-out;
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);
$easeSmooth: cubic-bezier(0.39, 0.575, 0.28, 0.995);
$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);

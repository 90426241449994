// header
.section-header {
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;

    width: 100%;
    padding: 30px 0;

    transition: all 0.3s;

    padding-right: 100px;
    &.header-bg-white {
        background-color: $white;
    }
}

.section-header-logo {
    position: absolute;

    width: 134px;
    & svg {
        width: 134px;
        height: 50px;
    }
}

.section-header-nav {
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;

    color: $white;
    & li {
        margin-left: 3%;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            display: none;

            margin-right: 0;
        }
        & a {
            position: relative;

            color: $white;
            opacity: 0.6;

            transition: all 0.6s;
        }
        a:hover {
            opacity: 1;
        }
        & a:before {
            position: absolute;
            content: '';
            z-index: -1;
            top: 50%;
            left: 50%;

            width: 35px;
            height: 35px;

            background: $blue;
            border-radius: 25px;
            opacity: 0;

            transform: translate(-50%, -50%) scale(0);

            transition: all 0.4s $easeSmooth;
        }
        & a:hover:before {
            opacity: 0.2;

            transform: translate(-50%, -50%) scale(1);
        }
        & a.active:before {
            opacity: 0.4;

            transform: translate(-50%, -50%) scale(1);
        }
    }
}

.is-black {
    .section-header-logo path {
        fill: $black !important;
    }
    .section-header-nav {
        & a:before {
            background: $blue;
        }
    }
    .section-header .section-header-nav li a {
        color: $black;
    }
    .section-header-nav li a {
        color: $black;
    }
    #section-header-mobilenav-open .fal {
        color: black;
    }
}

#section-header-mobilenav-open {
    display: none;
}

.menu-homepage-top {
    .section-header-nav li a.active:before {
        background-color: $white;
        opacity: 0.4;
    }
    .section-header-nav li a:hover:before {
        background-color: $white;
        opacity: 0.4;
    }
}

@media(max-width:1024px) {
    .menu-homepage-top .section-header-nav li a.active:before {
        background-color: $blue;
        opacity: 0.4;
    }
    .menu-homepage-top .section-header-nav li a:hover:before {
        background-color: $blue;
        opacity: 0.4;
    }
}

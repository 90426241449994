// Typos - Boutons - etc... 
html {
    font-family: $font-family;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-light;
    line-height: 1.78;

    color: $grey;
}

.section-hero-btn-arrow {
    position: relative;
    top: 3px;

    font-size: 25px;

    color: $blue;

    padding-left: 20px;
}

.section-header-nav {
    font-size: 0.95rem;
    font-weight: 500;
    line-height: 3;
    letter-spacing: 0.6px;

    text-transform: uppercase;
}

.title-1 {
    position: relative;

    font-size: 2.812rem;
    font-weight: 500;
    line-height: 1.35;

    color: $white;
    &:before {
        position: absolute;
        content: '';
        top: -43px;
        left: 0;

        width: 60px;
        height: 3px;

        background-color: $white;
    }
}

.title-2 {
    position: relative;

    font-size: 2.187rem;
    font-weight: 500;
    line-height: 1.4;

    color: $black;

    padding-top: 0;
    margin-left: 135px;
    margin-bottom: 30px;

    &.is-center {
        text-align: center;

        margin-left: 0;
        padding-right: 0;
        margin-bottom: 30px;
        &:before {
            left: calc(50% - 30px);
        }
    }
}

[data-namespace="about-us"] {
    .title-2 {
        position: sticky;
        top: 150px;
    }
}

.title-5 {
    font-size: 16px;
    line-height: 2;

    margin-bottom: 10px;
}

p {
    line-height: 2;

    max-width: 560px;
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0;
    }
}

.section-offers {
    counter-reset: section;
    .title-offer {
        position: sticky;
        top: 150px;
        /* &:after {
            position: absolute;
            top: 43px;
            left: -35px;
            counter-increment: section;
            content: counter(section) ".";
            font-weight: bold;
            color: $blue;
        } */
        @media #{$ie} {
            top: 0;
        }
    }
}

.title-3 {
    font-size: 2.187rem;
    font-weight: 500;

    color: $black;

    margin-bottom: 30px;
}

strong {
    color: $grey;
    &.dwl-links-title {
        display: block;
    }
}



.list-bullet {
    padding: 0;

    line-height: 2;

    list-style: none;
    & li {
        position: relative;

        padding-left: 1.3em;
        &:before {
            position: absolute;
            content: "";
            top: 12px;
            left: -1px;

            width: 12px;
            height: 12px;

            display: inline-block;

            background-image: url("/assets/img/favicon/favicon-32x32.png");
            background-size: cover;
        }
    }
}

.is-center .list-bullet {
    margin-left: 20px;
}

.list-categories {
    color: $blue;
}

.list-categories-title {
    font-size: 1.562rem;
    font-weight: normal;
    line-height: 1.4;

    color: $black;
}

@keyframes scalebtn {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.1);
    }
    100% {
        transform: scale(1);
    }
}

.btn-bubble {
    position: relative;
    z-index: 1;

    height: 50px;

    display: inline-block;

    font-size: 1.125rem;
    font-weight: 600;
    line-height: 43px;
    letter-spacing: 1.5px;

    color: $blue;

    cursor: pointer;
    &-disc {
        position: absolute;
        z-index: 0;
        top: 8px;
        left: -15px;

        width: 35px;
        height: 35px;

        background: linear-gradient(to right, rgba(28, 136, 160, 1) 0%, rgba(28, 136, 160, 1) 14%, rgba(28, 136, 160, 0.3) 100%);
        border-radius: 25px;
        opacity: 0.2;

        transition: all 0.3s $easeInOutCirc;
    }
    &:hover {
        .btn-bubble-disc {
            left: calc(100% - 30px);

            animation: scalebtn 0.3s;
        }
    }
    &.btn-extend {
        transition: all 0.3s $easeInOutCirc;
        &:hover {
            color: $blue;
        }
    }
}

.btn-bubble.is-center {
    margin: 0 auto;
}

.btn-text {
    transition: all 0.3s;

    text-decoration: underline;
    &:hover {
        color: $black;
    }
}

.dwl-links-container {
    margin-bottom: 40px;
}

.btn-downloadfile {
    position: relative;

    display: inline-block;

    opacity: 0.8;

    text-decoration: none;
    margin-bottom: 10px;
    i {
        padding-right: 5px;
    }
    &:before {
        position: absolute;
        content: '';
        z-index: 1;
        bottom: -2px;
        left: 0;

        width: 100%;
        height: 2px;

        background: $blue;
        opacity: 0.75;

        transform: scale(0, 1);
        transform-origin: 100% 50%;

        transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:hover, &.active {
        color: $blue;
    }
    &:hover:before {
        transform: scale(1, 1);
                transform-origin: 0 50%;

        -webkit-transform-origin: 0 50%;
    }
}

.section-getintouch {
    .btn-contact {
        position: relative;

        color: $black;
        .btn-contact-img {
            width: 14px;
            height: 14px;

            transform: rotate(90deg);

            margin-top: 10px;
        }
    }
    .contact-link {
        font-weight: bold;

        color: $black;
        &.privacy {
            text-decoration: underline;
        }
    }
}

.list-buttons > li:nth-child(2) {
    margin-bottom: 20px;
}

// footer
.section-footer {
    padding: 50px 0;

    background: $white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    color: $black;
}

.section-footer-title {
    font-size: 1.562rem;
    font-weight: normal;

    margin-bottom: 10px;
}

.section-footer-nav {
    & li {
        width: 100%;

        border: none;

        padding-top: 10px;
        text-decoration: none;
        list-style: none;
    }
}

.section-footer-row-1 {
    display: flex;

    margin-bottom: 100px;
    .section-footer-row-1-col-2 li:last-child {
        display: none;
    }
    .section-footer-row-1-col-1 {
        width: 37.5%;

        padding-right: 30px;
    }
    .section-footer-row-1-col-2 {
        width: 37.5%;
    }
    .section-footer-row-1-col-3 {
        width: 25%;
    }
    & .section-footer-row-1-col-2 .section-footer-nav {
        column-count: 2;
    }
}

.section-footer-row-2 {
    display: flex;
    justify-content: space-between;

    color: $grey;
}

.section-footer-logo {
    width: 225px;
}

.section-footer a {
    color: $grey;
}

.section-footer .footer-nav-links, .section-getintouch .contact-link {
    position: relative;

    display: inline-block;

    opacity: 0.8;

    margin-bottom: 3px;
    text-decoration: none;
    &:before {
        position: absolute;
        content: '';
        z-index: 1;
        bottom: -2px;
        left: 0;

        width: 100%;
        height: 2px;

        background: $blue;
        opacity: 0.75;

        transform: scale(0, 1);
        transform-origin: 100% 50%;

        transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
    }
    &:hover, &.active {
        color: $blue;
    }
    &:hover:before {
        transform: scale(1, 1);
                transform-origin: 0 50%;

        -webkit-transform-origin: 0 50%;
    }
}

.scrollMenu {
    position: fixed;
    right: 110px;
    bottom: -100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: $transition;
    &.is-scroll {
        bottom: -220px;

        display: flex;

        @media (max-width:$tablet) {
            bottom: -210px;
        }
    }
    &.is-hover {
        bottom: 0;
        .scrollMenu-chevron span {
            display: block;
        }
    }
    button {
        z-index: 1;

        width: 60px;
        height: 60px;

        font-size: 19px;

        background-color: $white;
        border-top: 1px solid rgba(28, 136, 160, 0.4);
        border-right: 1px solid rgba(28, 136, 160, 0.4);
        border-left: 1px solid rgba(28, 136, 160, 0.4);
        border-radius: 10px 10px 0 0 ;
        color: $blue;

        transition: $transition;

        cursor: pointer;

        margin-bottom: -1px;
        img {
            width: 50%;

            transition: $transition;
        }
        &:hover {
            cursor: pointer;
            img {
                transform: rotate(90deg);
            }
        }
    }
    ul {
        padding: 20px;

        background-color: $white;
        border-top: 1px solid rgba(28, 136, 160, 0.4);
        border-right: 1px solid rgba(28, 136, 160, 0.4);
        border-left: 1px solid rgba(28, 136, 160, 0.4);
        border-radius: 10px 10px 0 0;
        li {
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: #9f9f9f;

                transition: $transition;

                text-decoration: none;
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}

.svg-container-mobile {
    padding: 0 30px;

    margin-top: -50px;
    &-el {
        display: flex;
        align-items: center;

        font-size: 22px;

        box-shadow: 2px 3px 3px 0 rgba(0,0,0,0.2);

        transition: $transition;

        cursor: pointer;

        margin-bottom: 20px;
        &:hover {
            color: $blue;
        }
        > img {
            width: 3%;
            @media (max-width:$mobile) {
                width: 18px;
            }
        }
        > span {
            width: 100%;

            display: block;

            margin-left: 10px;
        }
    }
}

@media (max-width:1024px) {
    .scrollMenu {
        right: 50%;

        transform: translate(50%,0);
    }
    .scrollMenu {
        &.is-scroll {
            width: 100%;
        }
    }
}

/* grid */

.text-col-2 {
    column-count: 2;
    column-gap: 3rem;
    margin-left: 135px;
}

.grid-adaptive {
    margin: -40px 0 0 -40px;

    display: flex;
    flex-wrap: wrap;
}

.grid-adaptive:last-child {
    margin-bottom: 0;
}

.grid-adaptive .sub-column-100 {
    width: 100%;
}

.grid-adaptive .sub-column-80 {
    width: 80%;
}

.grid-adaptive .sub-column-75 {
    width: 75%;
}

.grid-adaptive .sub-column-70 {
    width: 70%;
}

.grid-adaptive .sub-column-66 {
    width: 66.666%;
}

.grid-adaptive .sub-column-60 {
    width: 60%;
}

.grid-adaptive .sub-column-50 {
    width: 50%;
}

.grid-adaptive .sub-column-40 {
    width: 40%;
}

.grid-adaptive .sub-column-33 {
    width: 33.333%;
}

.grid-adaptive .sub-column-25 {
    width: 25%;
}

.grid-adaptive .sub-column-20 {
    width: 20%;
}

.grid-adaptive.is-gutter-small {
    margin-left: -20px;
    margin-top: -20px;
    margin-bottom: 20px;
}

.grid-adaptive.is-gutter-small > [class*=sub-column-] {
    padding-left: 20px;
    margin-top: 20px;
}

.grid-adaptive > [class*=sub-column-] {
    padding: 40px 0 0 40px;
}

.grid-adaptive.is-gutter-large {
    margin-left: -80px;
    margin-top: -80px;
    margin-bottom: 80px;
}

.grid-adaptive.is-gutter-large > [class*=sub-column-] {
    padding-left: 80px;
    margin-top: 80px;
}

@media (max-width:640px) {
    .text-col-2 {
        column-count: 1;
    }
    .grid-adaptive .sub-column-80 {
        width: 100%;
    }
    .grid-adaptive .sub-column-75 {
        width: 100%;
    }
    .grid-adaptive .sub-column-70 {
        width: 100%;
    }
    .grid-adaptive .sub-column-66 {
        width: 100%;
    }
    .grid-adaptive .sub-column-60 {
        width: 100%;
    }
    .grid-adaptive .sub-column-50 {
        width: 100%;
    }
    .grid-adaptive .sub-column-40 {
        width: 100%;
    }
    .grid-adaptive .sub-column-33 {
        width: 50%;
    }
    .grid-adaptive .sub-column-25 {
        width: 50%;
    }
    .grid-adaptive .sub-column-20 {
        width: 33.333%;
    }
}

@media (max-width:480px) {
    .grid-adaptive .sub-column-20 {
        width: 50%;
    }
}

@media (max-width:320px) {
    .grid-adaptive > [class*=sub-column-] {
        width: 100%;
    }
}

@media (max-width:1220px) {
    .grid-adaptive.section-news .sub-column-33 {
        width: 50%;
    }
}

@media (max-width:550px) {
    .grid-adaptive.section-news > [class*=sub-column-] {
        width: 100%;
    }
}

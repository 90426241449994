/* reset */

html, body, p, ul, ol, form, input, button, select, textarea, h1, h2, h3, h4, h5, h6, dl, dt, dd, blockquote, address {
    margin: 0;
    padding: 0;

    border: 0;

    outline: 0;
}

ul, ol {
    list-style: none;
}

img, abbr {
    border: 0;
}


/* tags */

* {
    box-sizing: border-box;
}

body {
    width: 100%;

    overflow-x: hidden;
}

img {
    vertical-align: middle;
    max-width: 100%;
}

a {
    color: inherit;

    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

input[type=checkbox], input[type=radio], button {
    cursor: pointer;
}

figure {
    margin-inline-start: 0;
    margin-inline-end: 0;
    img {
        height: auto !important;
    }
}

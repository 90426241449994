//@import base;
@import "base/variables";
@import "base/reset";
@import "base/mixin";
@import "base/typo";
@import "base/grid";
@import "base/animations";
html {
    background: $white;
}

::selection {
    background: rgba($blue, 0.3);
    color: $black;
}

//@import layouts;
@import "layout/header";
@import "layout/getintouch";
@import "layout/footer";
//@import components;
@import "components/blocks";
@import "components/block-text";
@import "components/scroll-menu";
@import "components/backtomenu";
//@import pages;
@import "pages/homepage";
//@import plugins;
//@import mediaqueries;
@import "base/mediaqueries";

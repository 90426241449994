// blocks
.section-default {
    margin-top: 200px;
    margin-bottom: 200px;
}

.section-download-files {
    margin-top: -200px;
    margin-bottom: 200px;
}

.is-black {
    .section-grey {
        margin-top: 0;
    }
}

.flickity-container {
    position: relative;
}

.flickity-viewport {
    height: 555px;
}

// Slider textual
#flickity-textural {
    position: relative;
    left: 0;

    width: 100%;
    height: 150px;

    margin-top: 100px;
    .flickity-textual-item {
        width: 50%;
        height: 200px;

        font-size: 1.562rem;
        text-align: center;
        line-height: 1.6;

        transition: $transition;

        margin-right: 60px;
        &:hover {
            a {
                color: $blue;
            }
        }
        a {
            color: $grey;

            transition: $transition;
        }
    }
    .flickity-prev-next-button {
        z-index: 1;

        box-shadow: 0 0 0 2px rgba(28, 136, 160, 0.5);
        opacity: 1;

        transition: $transition;
        &:hover {
            background-color: $blue;
            box-shadow: 0 0 0 2px $blue;
            .flickity-button-icon {
                fill: $white;
            }
        }
        .flickity-button-icon {
            opacity: 0.5;

            fill: $blue;
        }
        &.previous {
            left: 21%;
        }
        &.next {
            right: 21%;
        }
        @media (max-width:$mobile) {
            width: 35px;
            height: 35px;
            &.previous {
                left: 9%;
            }
            &.next {
                right: 9%;
            }
        }
    }
    .flickity-page-dots {
        bottom: -25px;

        text-align: center;
        & .dot {
            width: 8px;
            height: 8px;
            margin: 0 10px;

            display: inline-block;

            background: $blue;
            border-radius: 50%;
            opacity: 0.25;

            cursor: pointer;
        }
        & .dot.is-selected {
            background-color: $blue;
            opacity: 1;
        }
    }
}

.flickity-shadow {
    position: absolute;
    top: 0;
    left: -30px;

    width: 100%;

    pointer-events: none;
    .flickity-shadow-left {
        position: absolute;

        width: 15%;
        height: 150px;

        background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    }
    .flickity-shadow-right {
        position: absolute;
        right: -75px;

        width: 15%;
        height: 150px;

        background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);

        transform: rotate(180deg);
    }
}

// Slider testimonials
.section-testimonials {
    margin-bottom: 200px;
    .wrapper {
        margin: 0 auto;
        padding: 0;

        padding-left: 100px;
        padding-right: 0;
    }
    .flickity-page-dots {
        position: absolute;
        top: 50%;
        bottom: inherit;
        left: -54px;

        width: auto;
        height: auto;

        display: flex;
        flex-direction: column;

        transform: translateY(-50%);
        & .dot {
            margin: 8px 0;
        }
    }
}

.flickity-testimonials {
    &-item {
        overflow: hidden;

        width: 100%;
        height: 100%;

        background-color: $blue;
        color: $white;
        &:before {
            position: absolute;
            content: '';
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            background-color: $blue;
            opacity: 0.75;
        }
    }
    &-img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        font-family: 'object-fit: cover;';

        object-fit: cover;
    }
    &-text {
        position: relative;
        z-index: 1;

        height: 100%;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        justify-content: center;

        color: $white;

        max-width: 850px;
        blockquote {
            font-size: 2.187rem;
            font-style: italic;
            font-weight: normal;
            line-height: 1.43;
        }
        cite {
            font-size: 1.25rem;
            font-style: normal;
            text-align: right;
            &:before {
                content: '\2014';
            }
        }
    }
}


/* TRANSITION CACHE */

.cache {
    position: fixed;
    z-index: 1000000000000;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;
}

.cache__item {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transform: scaleX(1);
    transform-origin: left center;

    pointer-events: none;
}

.cache__item:nth-child(1) {
    background: #e5eff0;
}

.cache__item:nth-child(2) {
    background: #d0ddde;
}

.cache__item:nth-child(3) {
    background: $white;
}

// News 

/* box */

.box-news {
    height: 100%;
    padding: 35px;

    display: block;

    background-color: $white;

    transition: 300ms;

    margin-bottom: 35px;
    text-decoration: none;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        box-shadow: 5px 5px 20px rgba(0,0,0,0.125);
        .sub-visual img {
            transform: scale(1.1);
        }
    }
    .sub-visual {
        position: relative;
        overflow: hidden;

        margin: -35px -35px 25px;

        display: block;
        i {
            position: absolute;
            z-index: 1;
            right: 40px;
            bottom: 40px;

            width: 44px;
            height: 44px;

            font-size: 10px;
            text-align: center;
            line-height: 44px;

            border-radius: 100%;
            color: $white;

            transition: 300ms;
        }
        img {
            width: 100%;

            transition: $transition;

            min-height: 250px;
            max-height: 250px;
            object-fit: contain;
        }
    }
    .sub-title {
        font-size: 22px;
        font-weight: 500;
        line-height: 1.4;

        color: #19191b;

        margin-bottom: 5px;
    }
    .sub-metadata {
        font-size: 15px;

        opacity: 0.5;

        margin-bottom: 15px;
    }
}

// POSTS

.section-default {
    .post-image {
        width: 100%;
        height: 60vh;

        font-family: 'object-fit: cover;';

        object-fit: cover;
        margin-bottom: 40px;
    }
    .post-container {
        width: 100%;
        margin: 0 auto;
        .post-image-container {
            margin: 0 auto;
        }
        .btn-center {
            margin: 0 auto;

            margin-top: 60px;
            margin-bottom: -120px;
        }
        .post-content {
            margin: 0 auto;

            max-width: 560px;
            margin-top: 30px;
            &-title {
                color: $black;
            }
            &-meta {
                margin-bottom: 20px;
            }
            figure {
                margin-inline-start: 0;
                margin-inline-end: 0;
                img {
                    height: auto !important;
                }
            }
        }
    }
}

.section-list {
    padding: 0;

    line-height: 2;

    list-style: none;
    & li {
        position: relative;

        padding-left: 1.3em;
        &:before {
            position: absolute;
            content: "";
            top: 12px;
            left: -1px;

            width: 12px;
            height: 12px;

            display: inline-block;

            background-image: url("/assets/img/favicon/favicon-32x32.png");
            background-size: cover;
        }
    }
}



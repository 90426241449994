        @media (max-width:1220px) {
    .title-offer {
        margin-left: 0;
    }
    #body-bg-fixed:before {
        opacity: 0.7;
    }
}

@media (max-width:$tablet) {
    //1024px
    html {
        font-size: 15px;
    }
    .wrapper {
        padding: 0 50px;
    }
    .section-hero {
        background-position-x: 75%;
        .hero-text {
            margin-left: 0;
        }
        .hero-cta {
            height: 9%;

            min-width: 230px;
        }
    }
    #body-bg-fixed {
        width: 100%;
    }
    .title-2 {
        margin-left: 0;
    }
    .text-col-2 {
        margin-left: 0;
    }
    .section-getintouch.is-sticky {
        display: none;
    }
    .section-getintouch {
        position: relative;
    }
    .section-header {
        position: fixed;

        height: 100px;

        padding-top: 0;
        padding-right: 0;
        .section-header-inner {
            transition: all 0.4s;

            padding-top: 30px;
            .section-header-logo {
                z-index: 2;
            }
        }
    }
    #section-header-mobilenav-open {
        z-index: 2;
    }
    .section-header-nav {
        z-index: 1;

        padding: 10px;

        display: flex;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        opacity: 0;

        & li {
            margin: 0;
            padding: 20px 0;
            a {
                color: $black;

                pointer-events: none;
            }
            &:last-child {
                display: block;
            }
        }
        &.open {
            overflow: scroll;

            height: calc(100vh - 70px);

            display: flex;

            opacity: 1;
            li a {
                font-size: 1rem;

                pointer-events: auto;
            }
        }
    }
    #section-header-mobilenav-open {
        display: block;

        cursor: pointer;

        float: right;
        & .fa-bars, & .fa-times {
            font-size: 2.3rem;

            color: $white;
        }
        & .fa-times {
            font-size: 2.7rem;

            color: black;

            margin-right: 13px;
            margin-top: -1px;
        }
    }
    .section-getintouch {
        z-index: 0;

        width: 100%;
        height: 100%;
    }
    .section-getintouch.open {
        margin-left: 0;
    }
    .section-getintouch-col-3 {
        width: 35%;
        .fa-times {
            display: none;
        }
    }
    .section-footer-row-1 {
        .section-footer-row-1-col-2 {
            .section-footer-nav li:last-child {
                display: block;
            }
        }
    }
    .section-grey .section-grey-content {
        padding-left: 60px;
        & p {
            max-width: 350px;
        }
        & .list-categories {
            width: 100%;
        }
    }
    .title-offer {
        margin-left: 30px;
    }
    .flickity-shadow {
        display: none;
    }
    .flickity-testimonials-text {
        padding: 50px;
        blockquote {
            font-size: 2rem;

            margin-bottom: 20px;
        }
    }
}

@media (max-width:$mobile) {
    //700px
    html {
        font-size: 15px;
    }
    .wrapper {
        padding: 0 30px;
    }
    #body-bg-fixed:before {
        background-size: 70%;
    }
    .section-hero {
        margin-bottom: 100px;
        .section-hero-body {
            top: 35%;

            padding: 0 10px 0 0;

            margin-left: 0;
            .title-1 {
                font-size: 2.1rem;
            }
        }
        &-title {
            font-size: 2.1rem;
            br {
                display: none;
            }
        }
    }
    .grid-adaptive > [class*=sub-column-] {
        padding: 0 0 0 40px;
    }
    .section-getintouch {
        flex-direction: column;
    }
    .section-getintouch-col-2 {
        width: 100%;
    }
    .section-getintouch-col-3 {
        width: 100%;
        height: 350px;

        margin-bottom: 100px;
        a {
            overflow: hidden;
        }
        img {
            transform: scale(2);
        }
    }
    .section-default, .section-default.is-center {
        margin-bottom: 100px;
        margin-top: 100px;
    }
    .section-default:first-child {
        margin-top: 200px;
    }
    .section-default {
        &.is-center {
            max-width: 530px;
        }
    }
    .title-2 {
        line-height: 1.2;

        margin-left: 0;
        margin-bottom: 30px;
        &.title-offer {
            margin-left: 34px;
        }
    }
    .text-col-2 {
        margin-left: 0;
    }
    .section-grey {
        display: inherit;

        margin-top: 220px;
        margin-bottom: 100px;
        padding-bottom: 10px;
        .section-grey-content {
            padding: 20px 30px 0 30px;
        }
        .section-grey-body {
            margin-bottom: 0;
        }
        & .list-categories {
            width: 60%;
            margin: 0 auto;

            display: block;
            & li {
                width: 100%;

                margin-bottom: 50px;
                padding-right: 20px;
                & a {
                    width: 100%;
                }
            }
        }
    }
    #flickity-textural {
        height: 230px;

        margin-top: 60px;
        & .flickity-viewport {
            height: 100% !important;
        }
        & .flickity-textural-item {
            height: 100%;
        }
    }
    .flickity-page-dots {
        bottom: -15px;
    }
    .flickity-shadow {
        display: none;
    }
    .section-footer-row-1 {
        flex-direction: column;

        margin-bottom: 0;
    }
    .section-footer-row-1 .section-footer-row-1-col-2 .section-footer-nav {
        column-count: 1;
    }
    .section-footer-row-1-col-1 {
        margin: 0 auto;

        padding-right: 0;
        .section-footer-logo {
            margin-bottom: 30px;
        }
    }
    .section-footer-row-1 .section-footer-row-1-col-2 {
        width: 100%;
        margin: 0 auto;

        text-align: center;

        margin-bottom: 20px;
    }
    .section-footer-row-1 .section-footer-row-1-col-3 {
        width: 100%;
        margin: 0 auto;

        text-align: center;

        margin-bottom: 50px;
    }
    .section-footer-row-2 {
        flex-direction: column;

        text-align: center;
    }
    .flickity-testimonials-text {
        padding: 60px;
        & cite {
            margin-top: 10px;
        }
    }
    .section-testimonials {
        .wrapper {
            padding-left: 0;
        }
    }
    .section-testimonials {
        .flickity-page-dots {
            top: inherit;
            bottom: -35px;
            left: inherit;

            width: 100%;

            flex-direction: row;
            justify-content: center;

            transform: translateY(0);
            & .dot {
                margin: 0 8px;
            }
        }
    }
    .section-grey .section-grey-visual {
        background-position-x: -130px;
    }
    .section-grey-visual {
        height: 390px;
    }
}

@media (max-width:550px) {
    .section-getintouch {
        .section-getintouch-col-2-infos {
            flex-direction: column;
            p {
                width: 100%;
            }
        }
    }
    .section-news-el {
        margin-bottom: 30px;
    }
    .section-getintouch-col-2 .form-contact-row {
        flex-direction: column;
        .form-contact-row-1 {
            width: 100%;
            padding: 0;
        }
        .form-contact-row-2 {
            width: 100%;
            padding: 0;
        }
    }
    .flickity-shadow {
        display: none;
    }
    .flickity-testimonials-text {
        padding: 30px;
        blockquote {
            font-size: 1.8rem;
        }
    }
    .section-footer-row-1 .section-footer-row-1-col-1 {
        width: 50%;

        padding-right: 0;
    }
}

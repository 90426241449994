// Unique page style
#body-bg-fixed {
    position: relative;
    z-index: 1;

    height: 100%;

    margin-right: 100px;
}


/* FADEING LANDING */

.fade-in {
    -webkit-animation: 0.6s ease-in 0s normal forwards 1 running fade;
}

@-webkit-keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* scrollbar */

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(28, 136, 160, 1);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(28, 136, 160, 1);
}

#body-bg-fixed:before {
    position: fixed;
    content: "";
    z-index: -20;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: block;

    background: url('/assets/img/imrim_bg_1.svg') no-repeat center center;
    background-size: 45%;
}

.wrapper {
    margin: 0 auto;
    padding: 0 $padding;

    max-width: 1440px;
}

.section-hero {
    position: relative;

    height: 100vh;

    background-image: url('/assets/img/hero_background.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    margin-bottom: 200px;
    .wrapper {
        height: 100%;
    }
    &-body {
        position: absolute;
        top: 63%;

        margin-left: 135px;
        padding-top: 43px;
    }
    &-cta {
        position: absolute;
        bottom: 0;

        padding: 20px 30px 20px 0;

        background-color: $white;
        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: -35vw;

            width: 39vw;
            height: 100%;

            background: $white;
        }
    }
    .mouse {
        position: absolute;
        bottom: 15px;
        left: 50%;

        width: 100px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        transform: translateX(-50%);

        text-decoration: none;
        min-height: 61px;
        &:hover {
            .mouse-wheel {
                display: none;
            }
            .mouse-icon {
                width: 40px;
                height: 40px;

                background-color: $white;
                border: 2px solid transparent;
                border-radius: 50%;
                opacity: 0.6;

                margin-bottom: -34px;
            }
        }
        &-icon {
            position: relative;
            z-index: -1;

            width: 20px;
            height: 35px;

            text-align: center;

            background-color: transparent;
            border: 2px solid $white;
            border-radius: 15px;

            transition: $transition;

            cursor: pointer;

            margin-bottom: 0;
        }
        &-wheel {
            width: 3px;
            height: 6px;
            margin: 2px auto 0;

            display: block;

            background-color: $white;
            border-radius: 50%;

            transition: $transition;

            -webkit-animation: 1.6s ease infinite wheel-up-down;
               -moz-animation: 1.6s ease infinite wheel-up-down;
                    animation: 1.6s ease infinite wheel-up-down;
        }
        &-text {
            z-index: 0;

            font-size: 15px;

            color: $white;

            transition: $transition;

            text-transform: uppercase;
            white-space: nowrap;
        }
    }

    @-webkit-keyframes wheel-up-down {
        0% {
            opacity: 0;

            margin-top: 2px;
        }
        30% {
            opacity: 1;
        }
        100% {
            opacity: 0;

            margin-top: 11px;
        }
    }
    @-moz-keyframes wheel-up-down {
        0% {
            opacity: 0;

            margin-top: 2px;
        }
        30% {
            opacity: 1;
        }
        100% {
            opacity: 0;

            margin-top: 11px;
        }
    }
    @keyframes wheel-up-down {
        0% {
            opacity: 0;

            margin-top: 2px;
        }
        30% {
            opacity: 1;
        }
        100% {
            opacity: 0;

            margin-top: 11px;
        }
    }
    @media (max-width:$mobile) {
        .mouse {
            bottom: 60px;
        }
    }
}

.section-default {
    & p.is-center {
        width: 100%;

        text-align: center;

        margin-top: 50px;
    }
    &.is-center {
        margin: 0 auto;

        max-width: 800px;
        margin-top: 200px;
        margin-bottom: 200px;
        & p {
            margin: 0 auto;

            max-width: 560px;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.section-grey {
    margin: 0 auto;

    display: flex;

    background-color: $light-grey;

    max-width: 100%;
    padding-top: 60px;
    padding-bottom: 80px;
    margin-top: 340px;
    margin-bottom: 150px;
    .section-grey-body {
        margin-bottom: 50px;
    }
    & p {
        max-width: 560px;
    }
    & .section-grey-content {
        padding-left: 140px;
        padding-right: 30px;
    }
    & .list-categories {
        margin: -50px 0 0 -50px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        max-width: 560px;
        & > li {
            position: relative;

            display: block;

            padding-top: 50px;
            padding-left: 50px;
            &:before {
                font-weight: bold;
            }
            & a {
                & .list-arrow {
                    position: relative;
                    top: 11px;

                    font-size: 1.625rem;

                    color: $blue;

                    transition: all 0.4s $easeSmooth;
                }
                & .list-categories-title {
                    transition: all 0.4s $easeSmooth;
                }
                &:hover .list-categories-title {
                    color: $blue;

                    transform: translate(15px, 0);
                }
                &:hover .list-arrow {
                    transform: translate(40px, 0);
                }
            }
        }
    }
}

.section-grey-visual {
    width: 540px;
    height: 570px;

    background-image: url('/assets/img/content-img.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    max-width: 100%;
    margin-top: -110px;
}

.section-offers-nav {
    height: 100%;
    padding: 0 20px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    background-color: rgba(28, 136, 160, 0.2);
    border-radius: 10px;

    min-height: 100px;
    .section-offers-nav-el {
        width: 200px;
        height: 100px;
        padding: 0 10px;

        display: flex;
        align-items: center;

        counter-increment: offers-scroll;
        &:hover .offer-scroll {
            border-bottom: 2px solid rgba(28, 136, 160, 1);
            color: black;
        }
        & .offer-scroll {
            position: relative;

            font-size: 1.125rem;

            border-bottom: 2px solid rgba(28, 136, 160, 0);
            color: $blue;

            transition: all 1s;

            padding-bottom: 3px;
            &:before {
                position: absolute;
                content: counter(offers-scroll)".";
                left: -18px;
            }
        }
    }
}

.section-partners {
    width: calc(100% + 20px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .section-partners-list {
        width: 150px;
        padding: 0 20px 30px 0;

        text-align: center;

        transition: all 0.4s;

        filter: grayscale(100%);
        &:hover {
            filter: none;
        }
    }
}

@mixin btn-apparence() {
    background-color: transparent;
    border: 0;

    appearance: none;
}

@mixin pseudo() {
    position: absolute;
    content: '';
}

@mixin objectFit() {
    width: 100%;
    height: 100%;

    font-family: 'object-fit: cover; object-position: 50% 50%;';

    object-fit: cover;
    object-position: 50% 50%;
}

html.scroll {
    overflow: hidden;

    height: 100%;
}

.section-getintouch {
    position: fixed;
    z-index: 12;
    top: 0;

    width: calc(100vw - 100px);
    height: 100%;

    display: flex;
    flex-direction: row;

    background-color: $white;

    transition: all 0.6s $easeInOutCirc;

    min-height: 100vh;
    margin-left: calc(100% - 100px);
    &:after {
        position: fixed;
        content: '';
        z-index: -100;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: rgba(0, 0, 0, 0.6);
        opacity: 0;

        transition: all 0.6s $easeInOutCirc;

        pointer-events: none;
    }
    &:before {
        @include pseudo();

        z-index: 0;
        top: 0;
        left: 1px;

        width: 100%;
        height: 100%;

        background: $white;

        pointer-events: none;
    }
    &.open {
        &:after {
            opacity: 1;
        }
    }
}

.section-getintouch-col-2, .section-getintouch-col-3 {
    opacity: 0;

    transform: translateX(80px);

    transition: all 1s $easeSmooth;
    transition-delay: 0;
}

.section-getintouch.open {
    overflow: hidden;

    margin-left: calc(0% + 100px);
    .section-getintouch-col-2, .section-getintouch-col-3 {
        opacity: 1;

        transform: translateX(0px);

        transition: all 1s $easeSmooth;
        transition-delay: 0.5s;
    }
    .section-getintouch-col-2 {
        height: auto;

        background-color: $white;

        overflow-y: scroll;
        padding-bottom: 50px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .section-getintouch-col-3 {
        transition-delay: 0.8s;
    }
}

.section-getintouch-col-1 {
    width: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;

    background-color: $white;
    border-left: 1px solid rgba(0, 0, 0, 0.1);

    cursor: pointer;
    .btn-contact {
        position: relative;

        transform: rotate(-180deg);

        -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
                writing-mode: vertical-lr;
        text-orientation: sideways;
    }
}

.section-getintouch-col-2 {
    width: 65%;

    padding-left: 8%;
    padding-right: 8%;
    padding-top: 50px;
    .title-2 {
        position: inherit;
        top: inherit;

        margin-left: 0;
    }
    .section-getintouch-col-2-infos {
        width: 100%;

        display: flex;

        margin-bottom: 40px;
        p {
            width: 50%;
        }
    }
    .form-contact-row {
        display: flex;
    }
}

.form-contact {
    margin-bottom: 40px;
    .form-contact-row {
        width: 100%;

        margin-top: 20px;
        input {
            height: 50px;
            padding: 10px 10px 10px 22px;

            background-color: $mid-grey;
            border: none;
        }
    }
    .form-contact-row-1 {
        width: 50%;
        padding: 0 10px 0 0;
        input {
            width: 100%;
        }
    }
    .form-contact-row-2 {
        width: 50%;
        padding: 0 0 0 10px;
        & input {
            width: 100%;
        }
    }
    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $grey;
    }
    ::-moz-placeholder {
        /* Firefox 19+ */
        color: $grey;
    }
    :-ms-input-placeholder {
        /* IE 10+ */
        color: $grey;
    }
    :-moz-placeholder {
        /* Firefox 18- */
        color: $grey;
    }
    .form-contact-comment {
        width: 100%;
        height: 80px;
        padding: 10px 10px 10px 22px;

        background-color: $mid-grey;
        border: none;

        resize: none;
    }
    .privacy {
        display: flex;
        align-items: center;
    }
    #privacy {
        margin-right: 10px;
    }
    .mandatory {
        margin-left: auto;
    }
}

.section-getintouch-col-3 {
    width: calc(35% - 100px);
    a {
        height: 100%;

        display: block;

        background-position: 50% 50%;
        background-size: cover;
    }
    .fa-times {
        position: absolute;
        top: 25px;
        right: 35px;

        padding: 10px 14px;

        font-size: 1.4rem;

        background-color: $black;
        border-radius: 50%;
        color: $white;

        transition: all 0.3s;

        cursor: pointer;
        &:hover {
            background-color: $white;
            color: $black;
        }
    }
}

.submit {
    padding: 12px 25px;

    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 1.5px;

    background-color: rgba(28, 136, 160, 0.2);
    border: none;
    border-radius: 22.5px;
    color: $blue;

    transition: all 0.3s;

    margin-top: 30px;
    -webkit-appearance: none;
    &:hover {
        background-color: rgba(28, 136, 160, 1);
        color: $white;

        cursor: pointer;
    }
}

.form-input {
    margin-bottom: 20px;
    label.error {
        color: #f76d6f;
    }
}

#body-bg-fixed.is-page {
    margin-right: 0;
}

.section-getintouch.is-page {
    position: relative;
    z-index: 10;

    width: 100%;

    padding-top: 150px;
    margin-left: 0;
    &:before, &:after {
        display: none;
    }
    .section-getintouch-col-3 a {
        background-position: 50% 50%;
        background-size: cover;
    }
    .section-getintouch-col-3 .fa-times {
        display: none;
    }
}

.success {
    padding: 20px;

    font-size: 1.3rem;
    text-align: center;

    background-color: rgba(28, 136, 160, 0.2);
    color: $black;

    margin-bottom: 70px;
    p {
        margin: 0 auto;
    }
}
